<template>
  <div>
    <save-transaction-sidebar />
    <h1>Transações</h1>
    <div v-if="allowMultiplePayments" class="d-flex align-items-center justify-content-end m-2">
      <b-button variant="primary" @click="addTransaction">
        <feather-icon icon="PlusCircleIcon" class="mr-50" />
        <span class="text-nowrap">Adicionar transação</span>
      </b-button>
    </div>
    <div v-if="loading" class="d-flex justify-content-center m-2">
      <b-spinner variant="primary" />
    </div>
    <template v-else-if="items.length > 0">
      <b-card no-body class="mb-0 m-2">
        <b-table
          :items="items"
          striped
          borderless
          responsive
          :fields="tableColumns"
          show-empty
          empty-text="Não foram encontrados pagamentos"
          :busy="loading"
        >
          <!-- Column: id -->
          <template #cell(id)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{
                data.item.id
              }}</span>
            </div>
          </template>
          <!-- Column: payment_type -->
          <template #cell(payment_type)="data">
            <div class="text-wrap">
              <span class="align-text-top">{{
                data.item.payment_type.name
              }}</span>
            </div>
          </template>
          <!-- Column: payment_gateway -->
          <template #cell(payment_gateway)="data">
            <div class="text-wrap">
              <span class="align-text-top" v-if="data.item.payment_gateway">{{
                data.item.payment_gateway.name
              }}</span>
            </div>
          </template>
          <!-- Column: payment_gateway -->
          <template #cell(installments)="data">
            <div class="text-wrap">
              <span class="align-text-top">{{
                data.item.installments
              }}</span>
            </div>
          </template>
          <!-- Column: payment_gateway_reference_id -->
          <template #cell(payment_gateway_reference_id)="data">
            <div class="text-wrap">
              <span class="align-text-top">{{
                data.item.payment_gateway_reference_id
              }}</span>
            </div>
          </template>
          <!-- Column: status -->
          <template #cell(status)="data">
            <div class="text-wrap">
              <span
                class="align-text-top"
                >{{ data.item.status | paymentStatus }}</span
              >
            </div>
          </template>
          <!-- Column: amount -->
          <template #cell(amount)="data">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize">{{
                data.item.amount | toCurrency
              }}</span>
            </div>
          </template>
          
          <!-- Column: updated_at  -->
          <template #cell(updated_at)="data">
            <div class="text-wrap">
              <span
                class="align-text-top"
                >{{ data.item.updated_at | dateTime }}</span
              >
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              right
              v-if="updateContractEnabled"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="edit(data.item.id)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="updatePayments"
              >
                <feather-icon icon="RotateCcwIcon" />
                <span class="align-middle ml-50">Sincronizar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card>
    </template>
    <template v-else>
      <span class="d-flex justify-content-center">
        Não foram encontradas transações
      </span>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { avatarText } from "@core/utils/filter";
import { UPDATE_ACTION, CONTRACT_RESOURCE } from "@/constants/resources";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import * as financial from "@/constants/financial";
import SaveTransactionSidebar from "../components/SaveTransactionSidebar";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    SaveTransactionSidebar
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "id", label: "ID" },
        { key: "payment_type", label: "Tipo" },
        { key: "payment_gateway", label: "Gateway" },
        { key: "installments", label: "Parcelas" },
        { key: "payment_gateway_reference_id", label: "Identificador" },
        { key: "status", label: "Status" },
        { key: "amount", label: "Valor" },
        { key: "updated_at", label: "Última atualização" },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      transactions: types.TRANSACTIONS,
      details: types.DETAILS_GENERAL_INFORMATION,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.transactions || [];
    },
    updateContractEnabled: function () {
      return this.$can(UPDATE_ACTION, CONTRACT_RESOURCE);
    },
    allowMultiplePayments: function () {
      return this.details?.allow_multiple_automatic_payments;
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    ...mapActions({
      getTransactions: types.GET_TRANSACTIONS,
      updateContractPayments: types.UPDATE_PAYMENTS,
      openSaveTransactionSidebar: types.OPEN_SAVE_TRANSACTION_SIDEBAR
    }),
    get() {
      this.loading = true;
      this.getTransactions({
        id: this.$route.params.id,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as transações. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    edit(id) {
      this.openSaveTransactionSidebar({
        id,
        saveAction: () => {
          this.get()
        }
      })
    },
    addTransaction() {
      this.openSaveTransactionSidebar({
        saveAction: () => {
          this.get()
        }
      })
    },
    updatePayments() {
      this.updateContractPayments(this.$route.params.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "A transação foi sincronizada com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.get()
        })
        .catch((err) => {
          if (err.response.status == 405) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text:
                  "Não é possivel sincronizar a transação. " +
                  err.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao sincronizar o contrato. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
